/*

NOTE: tag is not supported in horizontal menu

Array of object

Top level object can be:
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- nav Grp (top level grp is called header group) ---/

title
icon (if it's on top/second level)
children

/--- nav Item (top level item is called header link) ---/

icon (if it's on top/second level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)

*/
import dashboard from './dashboard'
import equipmenteCenter from './device-center'
import dataCenter from './data-center'
import ops from './ops'
import openApi from './open-api'
import system from './system'
import example from './example'

// Array of sections
export default [...dashboard, ...equipmenteCenter, ...dataCenter, ...ops, ...openApi, ...system, ...example]
