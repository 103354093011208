export default [
  {
    header: '设备中心',
    icon: 'HardDriveIcon',
    children: [
      {
        title: '设备管理',
        icon: 'HardDriveIcon',
        active: true,
        countTag: true,
        tagVariant: 'light-warning',
        children: [
          {
            title: '产品',
            route: 'device-center-device-manager-products',
            resource: 'device-product',
            icon: 'BoxIcon',
          },
          {
            title: '产品分类',
            route: 'device-center-device-manager-product-category',
            resource: 'device-category',
            icon: 'GridIcon',
          },
          {
            title: '设备',
            route: 'device-center-device-manager-devices',
            resource: 'device-api',
            icon: 'InboxIcon',
          },
          {
            title: '设备分组',
            route: 'device-center-device-manager-device-group',
            resource: 'device-group',
            icon: 'ListIcon',
          },
        ],
      },
      {
        title: '设备接入',
        icon: 'BoxIcon',
        active: true,
        tag: '4',
        tagVariant: 'light-warning',
        children: [
          {
            title: '接入协议',
            route: 'ec-agreement-view',
            resource: 'protocol-supports',
            icon: 'CodeIcon',
          },
          {
            title: '网络组件',
            route: 'ec-network-view',
            resource: 'network-config',
            icon: 'GlobeIcon',
          },
          {
            title: '接入网关',
            route: 'ec-gateway-view',
            resource: 'device-gateway',
            icon: 'LayersIcon',
          },
          {
            title: '证书',
            route: 'ec-certificate-view',
            resource: 'certificate',
            icon: 'BookmarkIcon',
          },
        ],
      },
    ],
  },
]
