export default [
  {
    header: '监控运维',
    icon: 'PackageIcon',
    children: [
      {
        title: '设备分布',
        icon: 'MapIcon',
        route: 'ops-location',
        resource: 'geo-manager',
        action: 'find-geo',
      },
      {
        title: '固件升级',
        icon: 'GitPullRequestIcon',
        route: 'ops-firmware',
        resource: 'firmware-manager',
      },
      {
        title: '设备告警',
        icon: 'AlertCircleIcon',
        route: 'ops-alarm',
        resource: 'device-alarm',
      },
    ],
  },

]
