export default [
  {
    header: '数据中心',
    icon: 'LayersIcon',
    children: [
      {
        title: '数据大屏',
        route: 'data-center-big-screen',
        // href: 'http://bi.xznky.cloudxin.cn',
        // href: 'http://bi.cloudxin.cn?auth=388c00a3838b4c02de719adbb342435f',
        icon: 'AirplayIcon',
        resource: 'big-screen-web',
        // resource: 'big-screen-web',
      },
      {
        title: '规则引擎',
        icon: 'CpuIcon',
        countTag: true,
        tagVariant: 'light-warning',
        active: true,
        children: [
          {
            title: '规则实例',
            route: 'data-center-rule-engine-instance',
            resource: 'rule-instance',
            icon: 'GitPullRequestIcon',
          },
          {
            title: '数据转发',
            route: 'data-center-rule-engine-forward',
            resource: 'rule-instance',
            icon: 'CornerDownRightIcon',
          },
          {
            title: '场景联动',
            route: 'data-center-rule-engine-scene-linkage',
            resource: 'rule-instance',
            icon: 'CropIcon',
          },
        ],
      },
    ],
  },
]
