export default [
  {
    header: '首页',
    icon: 'HomeIcon',
    // svg: 'media/icons/duotune/abstract/abs042.svg',
    children: [
      {
        title: '工作台',
        route: 'dashboard-workbench',
        icon: 'AirplayIcon',
        // svg: 'media/icons/duotune/general/gen008.svg',
        authz: false,
      },
      {
        title: '仪表盘',
        route: 'dashboard-home',
        icon: 'TrendingUpIcon',
        // svg: 'media/icons/duotune/graphs/gra010.svg',
        resource: 'dashboard',
      },
    ],
  },
]
