export default [
  {
    header: '系统管理',
    icon: 'PackageIcon',
    children: [
      {
        title: '通知管理',
        icon: 'BellIcon',
        countTag: true,
        tagVariant: 'light-success',
        children: [
          {
            title: '通知配置',
            icon: 'ToolIcon',
            route: 'system-notify-config',
            resource: 'notifier',
          },
          {
            title: '通知模版',
            icon: 'ClipboardIcon',
            route: 'system-notify-template',
            resource: 'template',
          },
        ],
      },
      // {
      //   title: '系统日志',
      //   icon: 'ClipboardIcon',
      //   tag: '2',
      //   tagVariant: 'light-success',
      //   children: [
      //     {
      //       title: '访问日志',
      //       route: 'system-log-access',
      //     },
      //     {
      //       title: '运行日志',
      //       route: 'system-log-runtime',
      //     },
      //   ],
      // },
      // {
      //   title: '账户中心',
      //   icon: 'UserIcon',
      //   countTag: true,
      //   tagVariant: 'light-primary',
      //   children: [
      //     {
      //       title: '基本信息',
      //       icon: 'UserIcon',
      //       route: 'system-usercenter-basesettings',
      //       authz: false,
      //     },
      //     {
      //       title: '安全中心',
      //       icon: 'UnlockIcon',
      //       route: 'system-usercenter-securitysettings',
      //       authz: false,
      //     },
      //     {
      //       title: '通知订阅',
      //       icon: 'MessageSquareIcon',
      //       route: 'system-usercenter-notification',
      //       authz: false,
      //     },
      //   ],
      // },
      {
        title: '系统设置',
        icon: 'SettingsIcon',
        countTag: true,
        tagVariant: 'light-success',
        children: [
          {
            title: '用户管理',
            icon: 'UsersIcon',
            route: 'system-setup-user',
            resource: 'user',
          },
          {
            title: '权限管理',
            icon: 'SlidersIcon',
            route: 'system-setup-permission',
            resource: 'permission',
          },
          {
            title: '角色管理',
            icon: 'SlackIcon',
            route: 'system-setup-role',
            resource: 'permission',
          },
          {
            title: '机构管理',
            icon: 'ListIcon',
            route: 'system-setup-org',
            resource: 'organization',
          },
          {
            title: '租户管理',
            icon: 'UserCheckIcon',
            route: 'system-setup-tenant',
            resource: 'tenant-manager',
          },
          {
            title: '访问日志',
            icon: 'FileTextIcon',
            route: 'system-log-access',
            resource: 'access-logger',
          },
          // {
          //   title: '系统配置',
          //   route: 'system-setup-config',
          // },
        ],
      },
    ],
  },
]
